// Pages/PageNotFound/index.js

import React from 'react';
import styles from './notfound.module.css';

function PageNotFound(props) {
	return (
		<div className={styles.container}>
			<h1>Page not found</h1>
		</div>
	);
}

export default PageNotFound;