// Context/actions.js


const ROOT_URL = 'http://192.168.0.43:8000';

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  };

  try {
      
    dispatch({ type: 'REQUEST_LOGIN' });
    //let response = await fetch(`${ROOT_URL}/reactlogin`, requestOptions);
    let data = "" //= await response.json();
data = '{"user": "david"}';
data = JSON.parse(data);
    if (data) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      localStorage.setItem('currentUser', JSON.stringify(data));
      return data
    }

    dispatch({ type: 'LOGIN_ERROR', error: data.errors[0] });
    return;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
}