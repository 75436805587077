// Pages/Login/index.js
 
import React from 'react';
import './login.module.css';
import { loginUser, useAuthState, useAuthDispatch } from '../../Context' 
import {Navigate} from 'react-router-dom';
import Form from "react-bootstrap/Form";
import LoaderButton from "../../Components/LoaderButton";

const Login = (props) => {

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAuthDispatch() //get the dispatch method from the useDispatch custom hook
  let loggedin = false
  let e = ""

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    let payload = {password, email}
      try {
        let response = await loginUser(dispatch, payload) //loginUser action makes the request and handles all the neccessary state changes
        console.log(response.user)
        if (response.user) {
        loggedin = true} //navigate to dashboard on success
      } catch (error) {
        console.log(error)
      }
      console.log(loggedin)

      return (
        <>
           {loggedin && (
              <Navigate to="/dashboard" replace={true} />
            )}
          <div>
    
          </div>
        </>
        );
    }
  

  return (

    <div className="Login">
      <Form onSubmit={handleLogin}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <div className="d-grid gap-2">
        <LoaderButton
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
            >
            Login
            </LoaderButton>
        </div>
      </Form>
    </div>
  )
}
 
export default Login;