import React from "react";
import Login from '../Pages/Login'
import Layout from '../Pages/LayoutNav'
import Dashboard from '../Pages/Dashboard'
import PageNotFound from '../Pages/PageNotfound'

const routes =[
    {
      path:'/login',
      component: Login,
      secured: false,
    },
    {
      path:'/dashboard',
      component: Dashboard,
      secured: true,
    },
    {
      path:'/*',
      component: PageNotFound,
      secured: false,
    },
  ]
   
  export default routes