// Pages/Dashboard/index.js

import React from 'react'
import styles from './dashboard.module.css'
import { useAuthState } from '../../Context'

const Dashboard = (props) => {

    const userDetails = useAuthState()
console.log(userDetails);
    return (
        <>
        <div style={{ padding: 10 }}>
            <div className={styles.dashboardPage} >
                <h1>
                    Dashboard for {userDetails.userDetails}
                </h1>
                <button className={styles.logoutBtn} >Logout</button>
            </div>
            <p>Welcome to the dashboard</p>
        </div>
        </>
    )
}

export default Dashboard