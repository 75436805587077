import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Routes,
} from 'react-router-dom';
import routes from './Config/routes.js';
import Layout from './Pages/LayoutNav'
import { AuthProvider } from "./Context";

function App() {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          {routes.map((route) => (
            <Route
              key={route.path}
              element={<route.component />}
              path={route.path}
            />
        ))}
        </Route>
      </Routes>
    </Router>
    </AuthProvider>
  );
}
 
export default App;
