import { Outlet, Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";


const Layout = () => {


  //alert(User.isAuthenticating);
  

    function handleLogout() {
        alert("Logging out?");
        //User.isAuthenticated(false);
    }

  return (
    (
    <>
        <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
            <Navbar.Brand href="\home">Board Manager</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="\dashboard">Home</Nav.Link>
            </Nav>
            <Nav>

            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>

      <Outlet />
    </>
    )
  )
};

export default Layout;